import consts from "./Consts";

export default class PageList {
  constructor(page_size){
    this.list = [];
    this.page = 0;
    this.page_size = page_size || 10
    this.isFinish = false;
    this.mergeList = null;
  }

  initPage(){
    this.list = [];
    this.page = 0;
    this.isFinish = false;
  }
  /*
  * 是否获取页面数据
  */
  isGetPage = function(type){
    if(this.isFinish){
      return false;
    }else if(type == consts.pageState.init){
      return true;
    }else if(type == consts.pageState.clickTab && this.page == 0){
      return true;
    }else if(type == consts.pageState.more && this.page != 0){
      return true;
    }
    return false;
  }

  /*
  * 获取分页数据
  */
  getPageParam(){
    return {
      offset:this.page*this.page_size,
      limit:this.page_size,
    }
  }
  /*
  * 无分页数据
  */
  getNoPageParam(){
    return {
      no_paper:1,
    }
  }

  async getDatas(getDatasCallback){
    const list = await getDatasCallback(this.getPageParam());
    if(list.results.length>0){
      if(typeof(this.mergeList)=="function"){
        this.mergeList(this.list,list.results);
      }else{
        this.list = [...this.list,...list.results];
      }

      this.page++;
    }
    if(list.results.length < this.page_size){
        this.isFinish = true;
    }
  }

  async getDatasNoPage(getDatasCallback){
    const list = await getDatasCallback(this.getNoPageParam());
    this.list = list.results;
    this.isFinish = true;
  }
}
