<template>
    <div class="CourseList" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="back()">
                        <van-icon name="arrow-left" />
                    </div>
                </van-col>
                <van-col span="16">
                    {{lang.selectCourse}}
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="content">
            <template v-if="CouresList.list.length>0">
            <van-list
                v-model:loading="loading"
                :finished="CouresList.isFinish"
                :finished-text="lang.noMore"
                @load="onLoad"
            >
                <template v-for="item in CouresList.list" :key="item" :title="item" >
                    <van-row>
                        <van-col offset="1" span="22">
                            <div class="class-item" @click="createLesson(item)">
                                <div class="color-line" :style="`background-color:#999`"></div>
                                <div class="class-content">
                                    <div class="class-content-info">
                                        <div class="class-content-name">{{item.en_name}}</div>
                                        <div class="class-content-name">{{item.name}}</div>
                                        <div class="marginTop">
                                            <van-row>
                                                <van-col span="18">
                                                    <span class="class-info">{{lang.time}}:{{item.kernel.start_time.substr(0,5)}} - {{item.kernel.end_time.substr(0,5)}}</span>
                                                </van-col>
                                            </van-row>
                                            <van-row>
                                                <van-col span="18">
                                                    <span class="class-info">{{lang.place}}: {{item.kernel.venue.detail}}</span>
                                                </van-col>
                                            </van-row>
                                            <van-row>
                                                <van-col span="18">
                                                    <span class="class-info">{{lang.studentsCount}}:{{item.kernel.students.length}}</span>
                                                </van-col>
                                            </van-row>
                                            <van-row>
                                                <van-col span="18">
                                                    <span class="class-info">{{lang.weekstr}}:{{getWeek(item.kernel.weekday)}}</span>
                                                </van-col>
                                            </van-row>
                                        </div>
                                    </div>
                                    <div class="class-content-btn">
                                        <div class="roll-call" :style="`background:url(${common.getRes('btn.png')});background-repeat: no-repeat;background-position-x: center;background-position-y: center;background-size: 100% 100%;`">
                                            {{lang.create}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-col>
                    </van-row>
                </template>
            </van-list>
            </template>
            <template v-else>
                <van-row justify="center" align="center" style="height:100%">
                <van-empty 
                    :image="common.getRes('null.png')"
                    :description="lang.no_courses" 
                />
                </van-row>
            </template>
        </div>

    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import Common from "../../library/Common";
import { useRouter } from 'vue-router';
import request from '../../library/MyRequest';
import PageList from "../../library/PageList";
import "@/css/course.css";

export default {
    name: 'CourseList',
    setup(){
        const common = Common;
        const router = useRouter();
		common.chechLogin(router);
        
        let state = reactive({
            lang:{
                langBtn:"",
                selectCourse:"",
                create:"",
                time:"",
                place:"",
                studentsCount:"",
                no_courses:"",
                weekstr:'',
                weeks:'',
            },
            CouresList:new PageList(8),
            loading:true,
        });

        
        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageBtn();
        }

        const back = function(){
            router.push({name: 'LessonList'});
        }

        const getWeek = function(weekday){
            var weekdaySorted = weekday.sort((a,b)=>{return a.index -b.index;});
            var result = [];
            for(var weekdayObject of weekdaySorted){
                var str = state.lang.weeks[weekdayObject.index-1];
                result.push(str);
            }
            return result.join(",");
        }

        const getcourse = async()=>{
            if(!state.CouresList.isFinish){
                await state.CouresList.getDatas(async(pageParam)=>{
                    var res = await request.get(`/teacher/course/?offset=${pageParam.offset}&limit=${pageParam.limit}`);
                    return {results:res.data.results};
                });
            }
            state.loading = false;
        }

        const onLoad = async()=>{
            await getcourse();
        }

        const createLesson = function(course){
            router.push({
                name:'CreateLesson',
                params:{
                    course:JSON.stringify(course),
                }
            });
        }

        getcourse();
        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            back,
            onLoad,
            createLesson,
            getWeek,
        };
    }
}
</script>

<style scoped>
.CourseList{
    width: 100%;
    height: 100%;
    background: #F6F6F6;
}
.content{
    font-size: 28px; 
    width: 100%;
    height:calc(100% - 1.33333rem);
    overflow-y:auto;
}

</style>